<template>
  <div>
    <!-- <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :comboItems="useFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="useFlag"
            label="LBLUSEFLAG"
            v-model="searchParam.useFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box> -->
    <div class="row">
      <!-- <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
        <c-table
          ref="table"
          title="LBL0003202"
          tableId="company"
          :columns="grid.columns"
          :data="grid.data"
          :filtering="false"
          :columnSetting="false"
          :usePaging="false"
          @rowClick="rowClick"
        >
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
            </q-btn-group>
          </template>
        </c-table>
      </div> -->
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <q-form ref="editForm">
          <!-- 회사 정보 -->
          <c-card title="LBL0003203" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <!-- 등록 -->
                <!-- <c-btn v-if="editable" label="LBLREG" icon="add" @btnClicked="addData" /> -->
                <!-- 저장 -->
                <c-btn
                  v-if="saveEditable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="company"
                  :mappingType="mappingType"
                  label="LBLSAVE"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-4">
                <!-- 회사명 -->
                <c-text
                  :required="true"
                  :disabled="!saveMode"
                  :editable="editable"
                  label="회사명(상호)"
                  name="companyName"
                  v-model="company.companyName">
                </c-text>
              </div>
              <div class="col-4">
                <!-- 사업자번호 -->
                <c-text
                  :disabled="!saveMode"
                  :editable="editable"
                  :bizNumberCheck="true"
                  label="LBL0003205"
                  name="bizNo"
                  v-model="company.bizNo">
                </c-text>
              </div>
              <div class="col-4">
                <!-- 대표자 -->
                <c-text
                  :disabled="!saveMode"
                  :editable="editable"
                  label="LBL0003206"
                  name="managerName"
                  v-model="company.managerName">
                </c-text>
              </div>
              <div class="col-12">
                <c-text
                  :disabled="!saveMode"
                  :editable="editable"
                  label="소재지"
                  name="addr"
                  v-model="company.addr">
                </c-text>
              </div>
              <div class="col-2">
                <c-select
                  :disabled="!saveMode"
                  :editable="editable"
                  codeGroupCd="FIRE_MNG_GRADE_CD"
                  label="소방안전관리대상물 등급"
                  itemText="codeName"
                  itemValue="code"
                  name="fireMngGradeCd"
                  type="edit"
                  v-model="company.fireMngGradeCd">
                </c-select>
              </div>
              <div class="col-2">
                <c-text
                  :disabled="!saveMode"
                  :editable="editable"
                  label="지하층"
                  name="underFloor"
                  v-model="company.underFloor">
                </c-text>
              </div>
              <div class="col-2">
                <c-text
                  :disabled="!saveMode"
                  :editable="editable"
                  label="지상층"
                  name="upFloor"
                  v-model="company.upFloor">
                </c-text>
              </div>
              <div class="col-2">
                <c-text
                  :disabled="!saveMode"
                  :editable="editable"
                  label="연면적(㎡)"
                  name="totalFloorArea"
                  v-model="company.totalFloorArea">
                </c-text>
              </div>
              <div class="col-2">
                <c-text
                  :disabled="!saveMode"
                  :editable="editable"
                  label="바닥면적(㎡)"
                  name="floorArea"
                  v-model="company.floorArea">
                </c-text>
              </div>
              <div class="col-2">
                <c-text
                  :disabled="!saveMode"
                  :editable="editable"
                  label="동수"
                  name="buildCount"
                  v-model="company.buildCount">
                </c-text>
              </div>
              <!-- <div class="col-3">
                <c-checkbox
                  :disabled="!saveMode"
                  :editable="editable"
                  :isFlag="true"
                  label="LBLUSEFLAG"
                  name="useFlag"
                  v-model="company.useFlag"
                />
              </div> -->
            </template>
          </c-card>
        </q-form>
      </div>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'company-manage',
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'companyName',
            field: 'companyName',
            // 회사명
            label: 'LBL0003204',
            align: 'left',
            style: 'width:70%',
            sortable: false,
          },
        ],
        data: [],
      },
      searchParam: {
        useFlag: 'Y',
      },
      company: {
        mdmCompanyId: '',  // 회사 일련번호
        companyName: '',  // 회사명(상호)
        bizNo: '',  // 사업자번호
        managerName: '',  // 대표자
        addr: '',  // 소재지
        underFloor: '',  // 지하층
        upFloor: '',  // 지상층
        totalFloorArea: '',  // 연면적
        floorArea: '',  // 바닥면적
        buildCount: '',  // 동수
        fireMngGradeCd: null,  // 소방안전관리대상물 등급
        useFlag: 'Y',  // 사용여부
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
      },
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      editable: true,
      saveMode: false,
      isSave: false,
      listUrl: '',
      detailUrl: '',
      saveUrl: '',
      mappingType: 'POST',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    saveEditable() {
      return this.editable && this.saveMode
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.mdm.company.list.url;
      this.detailUrl = selectConfig.mdm.company.get.url;
      this.saveUrl = transactionConfig.mdm.company.insert.url;
      // code setting
      // list scope
      this.rowClick();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    rowClick() {
      this.$http.url = this.$format(this.detailUrl, 'C000000001');
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.$_.extend(this.company, _result.data);
        this.saveMode = true;
      },);
    },
    addData() {
      Object.assign(this.$data.company, this.$options.data().company);
      this.saveMode = true;
    },
    saveData() {
      if (this.company.mdmCompanyId) {
        this.mappingType = 'PUT'
        this.saveUrl = transactionConfig.mdm.company.update.url;
      } else {
        this.mappingType = 'POST'
        this.saveUrl = transactionConfig.mdm.company.insert.url;
      }

      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.company.regUserId = this.$store.getters.user.userId
              this.company.chgUserId = this.$store.getters.user.userId

              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      this.company.mdmCompanyId = result.data
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
      this.rowClick({ mdmCompanyId: result.data });
    },
  }
};
</script>
