var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c(
            "q-form",
            { ref: "editForm" },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "LBL0003203" },
                },
                [
                  _c(
                    "template",
                    { slot: "card-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _vm.saveEditable
                            ? _c("c-btn", {
                                attrs: {
                                  url: _vm.saveUrl,
                                  isSubmit: _vm.isSave,
                                  param: _vm.company,
                                  mappingType: _vm.mappingType,
                                  label: "LBLSAVE",
                                  icon: "save",
                                },
                                on: {
                                  beforeAction: _vm.saveData,
                                  btnCallback: _vm.saveCallback,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      { staticClass: "col-4" },
                      [
                        _c("c-text", {
                          attrs: {
                            required: true,
                            disabled: !_vm.saveMode,
                            editable: _vm.editable,
                            label: "회사명(상호)",
                            name: "companyName",
                          },
                          model: {
                            value: _vm.company.companyName,
                            callback: function ($$v) {
                              _vm.$set(_vm.company, "companyName", $$v)
                            },
                            expression: "company.companyName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-4" },
                      [
                        _c("c-text", {
                          attrs: {
                            disabled: !_vm.saveMode,
                            editable: _vm.editable,
                            bizNumberCheck: true,
                            label: "LBL0003205",
                            name: "bizNo",
                          },
                          model: {
                            value: _vm.company.bizNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.company, "bizNo", $$v)
                            },
                            expression: "company.bizNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-4" },
                      [
                        _c("c-text", {
                          attrs: {
                            disabled: !_vm.saveMode,
                            editable: _vm.editable,
                            label: "LBL0003206",
                            name: "managerName",
                          },
                          model: {
                            value: _vm.company.managerName,
                            callback: function ($$v) {
                              _vm.$set(_vm.company, "managerName", $$v)
                            },
                            expression: "company.managerName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("c-text", {
                          attrs: {
                            disabled: !_vm.saveMode,
                            editable: _vm.editable,
                            label: "소재지",
                            name: "addr",
                          },
                          model: {
                            value: _vm.company.addr,
                            callback: function ($$v) {
                              _vm.$set(_vm.company, "addr", $$v)
                            },
                            expression: "company.addr",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-2" },
                      [
                        _c("c-select", {
                          attrs: {
                            disabled: !_vm.saveMode,
                            editable: _vm.editable,
                            codeGroupCd: "FIRE_MNG_GRADE_CD",
                            label: "소방안전관리대상물 등급",
                            itemText: "codeName",
                            itemValue: "code",
                            name: "fireMngGradeCd",
                            type: "edit",
                          },
                          model: {
                            value: _vm.company.fireMngGradeCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.company, "fireMngGradeCd", $$v)
                            },
                            expression: "company.fireMngGradeCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-2" },
                      [
                        _c("c-text", {
                          attrs: {
                            disabled: !_vm.saveMode,
                            editable: _vm.editable,
                            label: "지하층",
                            name: "underFloor",
                          },
                          model: {
                            value: _vm.company.underFloor,
                            callback: function ($$v) {
                              _vm.$set(_vm.company, "underFloor", $$v)
                            },
                            expression: "company.underFloor",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-2" },
                      [
                        _c("c-text", {
                          attrs: {
                            disabled: !_vm.saveMode,
                            editable: _vm.editable,
                            label: "지상층",
                            name: "upFloor",
                          },
                          model: {
                            value: _vm.company.upFloor,
                            callback: function ($$v) {
                              _vm.$set(_vm.company, "upFloor", $$v)
                            },
                            expression: "company.upFloor",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-2" },
                      [
                        _c("c-text", {
                          attrs: {
                            disabled: !_vm.saveMode,
                            editable: _vm.editable,
                            label: "연면적(㎡)",
                            name: "totalFloorArea",
                          },
                          model: {
                            value: _vm.company.totalFloorArea,
                            callback: function ($$v) {
                              _vm.$set(_vm.company, "totalFloorArea", $$v)
                            },
                            expression: "company.totalFloorArea",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-2" },
                      [
                        _c("c-text", {
                          attrs: {
                            disabled: !_vm.saveMode,
                            editable: _vm.editable,
                            label: "바닥면적(㎡)",
                            name: "floorArea",
                          },
                          model: {
                            value: _vm.company.floorArea,
                            callback: function ($$v) {
                              _vm.$set(_vm.company, "floorArea", $$v)
                            },
                            expression: "company.floorArea",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-2" },
                      [
                        _c("c-text", {
                          attrs: {
                            disabled: !_vm.saveMode,
                            editable: _vm.editable,
                            label: "동수",
                            name: "buildCount",
                          },
                          model: {
                            value: _vm.company.buildCount,
                            callback: function ($$v) {
                              _vm.$set(_vm.company, "buildCount", $$v)
                            },
                            expression: "company.buildCount",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }